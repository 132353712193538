const countryCodesDict = {
  FIN: 'Финляндия',
  CHN: 'Китай',
  RUS: 'Россия',
  EU:  'Европа',
  DEU: 'Германия',
  KOR: 'Корея',
  ITA: 'Италия',
  SVN: 'Словения',
  THA: 'Таиланд',
  AUT: 'Австрия',
  GBR: 'Англия',
  ESP: 'Испания',
  CHN_FIN: 'Китай/Финляндия',
}

export const countryCodes = Object.entries(countryCodesDict)

export const verboseCountryCode = (code) => {
  if (!(code in countryCodesDict))
    return code
  return countryCodesDict[code]
}
