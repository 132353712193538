import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { loginApiUrl } from '../api';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

function Alert(props) {
  return <MuiAlert elevation={ 6 } variant="filled" { ...props } />;
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  textCenter: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
  }
}));

const Login = ({ className, onLogin }) => {
  const classes = useStyles();
  const [failedLogin, setFailedLogin] = useState(false);

  const handleFailedLogin = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFailedLogin(false);
  };

  return (
      <Box className={ className }>
        <Formik
            initialValues={ { username: '', password: '' } }
            validate={ values => {
              const errors = {};
              if (!values.username) {
                errors.username = 'Обязательно';
              }
              if (!values.password) {
                errors.password = 'Обязательно';
              }
              return errors;
            } }
            onSubmit={ (values, { setSubmitting }) =>
                (async() => {
                  try {
                    let res = await axios.post(loginApiUrl, {
                      username: values.username,
                      password: values.password,
                    });
                    if (res.status === 200) {
                      let auth = {
                        token: res.data.token,
                      };
                      let decoded = jwt_decode(auth.token);
                      auth.tokenData = decoded;
                      auth.user = decoded.user;
                      onLogin(auth);
                    } else {
                      console.log(res);
                    }
                  } catch (e) {
                    console.error(e);
                    if (e.response.status === 400) {
                      setFailedLogin(true);
                    }
                  }
                })()
            }
        >
          { ({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
               /* and other goodies */
             }) => (
              <Paper elevation={3} className={classes.paper}>
                <form className={ classes.form } onSubmit={ handleSubmit }>
                  <Backdrop className={ classes.backdrop }
                            open={ isSubmitting }>
                    <CircularProgress color="secondary"/>
                  </Backdrop>
                  <Snackbar open={ failedLogin } autoHideDuration={ 6000 }
                            onClose={ handleFailedLogin }>
                    <Alert onClose={ handleFailedLogin } severity="error">
                      Не правильные данные
                    </Alert>
                  </Snackbar>
                  <Box className={ classes.textCenter }>
                    <Typography variant={ 'h6' }>入る</Typography>
                  </Box>
                  <TextField className={ classes.input }
                             error={ errors.username && touched.username }
                             helperText={ errors.username }
                             variant="outlined"
                             label="Имя Пользователя"
                             name='username'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.username }/>
                  <TextField className={ classes.input }
                             error={ errors.password && touched.password }
                             helperText={ errors.password }
                             variant="outlined"
                             label="Пароль"
                             name='password'
                             type='password'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.password }/>
                  <Button className={ classes.input }
                          disabled={ isSubmitting }
                          type='submit'
                          variant="contained" color="primary">
                    { isSubmitting ?
                        <CircularProgress color="secondary"/>
                        :
                        'Войти'
                    }
                  </Button>
                </form>
              </Paper>)
          }
        </Formik>
      </Box>
  );
};

export default Login;
