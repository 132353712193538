import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PapersTable from './papersTable';
import PaperView from './paperView';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  papersTable: () => ({
    // marginBottom: theme.spacing(2)
  }),
  paperView: {},
}));

// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const PapersManager = ({ className, style,
                         isSupplyViewer, isSupplyMan, isSupplyAdmin,
                         papers, paperProviders,
                         onAdd, onDelete, onEdit }) => {
  const classes = useStyles();

  // const paperViewRef = useRef(null)

  const [selectedPaper, setSelectedPaper] = useState(null);
  const [paperViewMinimized, setPaperViewMinimized] = useState(true);

  useEffect(() => {
    if (papers && selectedPaper) {
      let found = false;
      papers.forEach(v => {
        if (v.id === selectedPaper.id) {
          setSelectedPaper(v);
          found = true;
        }
      });
      if (!found) {
        setSelectedPaper(null);
      }
    }
  }, [papers]);

  return (
      <Box className={ className } style={ style }>
        <Box className={ classes.container }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ (isSupplyMan || isSupplyAdmin) ? paperViewMinimized
                ? 11
                : 8 : 12 }>
              <Paper className={ classes.paper }>
                <PapersTable papers={ papers }
                             style={ { maxHeight: '100vh' } }
                             paperProviders={ paperProviders }
                             onEditClick={ (paper) => {
                               // scrollToRef(paperViewRef)
                               setSelectedPaper(paper);
                               setPaperViewMinimized(false);
                             } }
                             onAddClick={ () => {
                               // scrollToRef(paperViewRef)
                               setSelectedPaper(null);
                               setPaperViewMinimized(false);
                             } }/>
              </Paper>
            </Grid>
            { (isSupplyMan || isSupplyAdmin) && (
                <Grid item xs={ paperViewMinimized ? 1 : 4 }>
                  <Paper className={ classes.paper }
                         style={ { height: '100%' } }>
                    <PaperView isSupplyMan={ isSupplyMan }
                               isSupplyAdmin={ isSupplyAdmin }
                               onDelete={ onDelete }
                               onAdd={ onAdd }
                               onEdit={ onEdit }
                               style={ { height: '100%' } }
                               minimized={ paperViewMinimized }
                               onMinimize={ () => setPaperViewMinimized(true) }
                               onExpand={ () => setPaperViewMinimized(false) }
                               paper={ selectedPaper }
                               paperProviders={ paperProviders }/>
                  </Paper>
                </Grid>) }
          </Grid>
        </Box>
      </Box>
  );
};

export default PapersManager;
