import React, { Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { verboseCountryCode } from '../../../api/utils/countryCodes';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Fuse from 'fuse.js';
import green from '@material-ui/core/colors/green';
import {
  getPaperTypeColor,
  verbosePaperType,
} from '../../../api/warehouse/paper';
import EditIcon from '@material-ui/icons/Edit';
import red from '@material-ui/core/colors/red';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // maxHeight: '100%',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  toolbar: {
    // display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  toolbarHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  toolbarRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tableRow: {},
  buttonSuccess: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[700]),
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -16,
    left: 3,
    zIndex: 1,
  },
  wrapper: {
    display: 'inline',
    position: 'relative',
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

const PapersTable = ({
                       className, style,
                       papers, onEditClick, onAddClick,
                     }) => {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    search: '',
    typeName: '',
    provider: null,
    density: 0,
    height: 0,
    width: 0,
    price: 0,
  });
  const [parsedPapersList, setParsedPapersList] = useState([]);
  const [papersList, setPapersList] = useState([]);
  const [providers, setProviders] = useState([]);
  const timer = React.useRef();

  const [copySuccess, setCopySuccess] = React.useState('');
  const [searching, setSearching] = React.useState(false);
  const [copying, setCopying] = React.useState('');

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const columns = [
    {
      id: 'action', key: 'id', label: 'Действия', style: {},
      format: (value, paper) => (
          <Fragment>
            <Fab size="small" className={ classes.fab }
                 color="secondary" onClick={ () => {onEditClick(paper);} }>
              <EditIcon/>
            </Fab>
            <CopyToClipboard
                text={ `${ paper.name } ${ paper.typeNameVerbose } \t ${ verboseCountryCode(
                    paper.country) } \t ${ paper.density } \t ${ paper.width } \t ${ paper.height } \t ${ paper.price /
                100 }` }
                options={ { format: 'text/plain' } }
                onCopy={ () => {
                  if (copying === '') {
                    setCopySuccess('');
                    setCopying(paper.id);
                    timer.current = setTimeout(() => {
                      setCopySuccess(paper.id);
                      setCopying('');
                    }, 500);
                  }
                } }>
              <div className={ classes.wrapper }>
                <Fab size="small"
                     disabled={ copying === paper.id }
                     color={ 'primary' }
                     className={ `${ clsx({
                       [classes.buttonSuccess]: copySuccess === paper.id,
                     }) } ${ classes.fab }` }>
                  { copySuccess === paper.id ? <CheckIcon/> : <FileCopyIcon/> }
                </Fab>
                { copying && <CircularProgress size={ 50 }
                                               className={ classes.fabProgress }/> }
              </div>
            </CopyToClipboard>
          </Fragment>
      ),
    },
    {
      id: 'id', key: 'id', label: 'id', style: {},
      format: (value) => (
          <Typography noWrap style={ { width: 40 } }>
            { value }
          </Typography>
      ),
    },
    {
      id: 'name', key: 'name', label: 'Название', style: { minWidth: 85 },
      format: (value, paper) => (
          <Typography noWrap>
            { paper.name || '-' }
          </Typography>
      ),
    },
    {
      id: 'provider',
      key: 'provider',
      label: 'Поставщик',
      style: { minWidth: 85 },
      format: (value, paper) => (
          <Typography noWrap>
            { paper.provider || '-' }
          </Typography>
      ),
    },
    {
      id: 'typeNameVerbose',
      key: 'typeNameVerbose',
      label: 'Тип',
      style: { minWidth: 170 },
    },
    {
      id: 'country', key: 'country', label: 'Страна', style: { minWidth: 64 },
      format: (value) => verboseCountryCode(value),
    },
    {
      id: 'density',
      key: 'density',
      label: 'Плотность',
      style: { minWidth: 16 },
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'width/height',
      key: 'width/height',
      label: 'Ширина / Длина',
      style: { minWidth: 16 },
      align: 'right',
      format: (value, paper) =>
          <Fragment>
            { paper.width.toFixed(2) }<strong> / </strong>
            { paper.height.toFixed(2) }
          </Fragment>,
    },
    {
      id: 'price',
      key: 'price',
      label: 'Цена',
      style: { minWidth: 16 },
      align: 'right',
      format: (value, paper) => {
        if (!paper.priceRecords || paper.priceRecords.length === 1) {
          return <Typography variant={ 'body1' }>{ paper.price /
          100 } ₸</Typography>;
        }
        let priceDelta = paper.price -
            paper.priceRecords[paper.priceRecords.length - 2].price;
        let percent = priceDelta /
            (paper.priceRecords[paper.priceRecords.length - 2].price / 100);
        let isIncreased = priceDelta > 0;
        return (
            <Fragment>
              <Typography variant={ 'body1' }
                          style={ {
                            color: isIncreased
                                ? red[500]
                                : green[500],
                          } }>
                <Typography variant='caption'>{ Math.abs(percent)
                    .toFixed(2) }%</Typography>
                { isIncreased ? <ArrowDropUpIcon
                        fontSize={ 'small' }/> :
                    <ArrowDropDownIcon fontSize={ 'small' }/> }
                { (paper.price / 100).toFixed(2) } ₸
              </Typography>
            </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    let parsed = [];
    let pr = [];
    if (papers) {
      parsed = papers.map((paper) => {
        if (paper.provider && paper.provider !== '') {
          pr.push(paper.provider);
        }

        return ({
          ...paper,
          typeNameVerbose: verbosePaperType(paper.typeName),
        });
      }).sort((a, b) => {
        if (a.typeName < b.typeName) {
          return -1;
        }
        if (a.typeName > b.typeName) {
          return 1;
        }
        if (a.typeName === b.typeName) {
          if (a.density > b.density) {
            return -1;
          }
          if (a.density < b.density) {
            return 1;
          }
        }
        return 0;
      });
    }
    pr = [...new Set(pr)];
    pr = pr.map((item) => ({
      'title': item,
    }));
    setProviders(pr);
    setParsedPapersList(parsed);
    setPapersList(parsed);
  }, [papers]);

  // useEffect(() => {
  //   if (searchValue === '' || searchValue === null) {
  //     setUsersList(parsedPapersList);
  //   } else {
  //     let res = fuse.search(searchValue);
  //     setUsersList(res.map(v => v.item));
  //   }
  // }, [parsedPapersList]);

  const handleFilterChange = (filter) => {
    setFilter(filter);
  };

  const applyFilter = async() => {
    setSearching(true);
    try {
      console.log(filter)
      const valueFilter = (paper) => {
        return (
            !(filter.provider && filter.provider.title !== paper.provider)
            && !(filter.density && filter.density !== paper.density)
            && !(filter.height && filter.height !== paper.height)
            && !(filter.width && filter.width !== paper.width)
        );
      };
      if (filter.search) {
        const fuse = new Fuse(parsedPapersList.filter(valueFilter), {
          keys: ['name', 'country', 'typeName', 'typeNameVerbose', 'density'],
        });
        let res = fuse.search(filter.search);
        console.log(res);
        setPapersList(res.map(item => item.item));
      } else {
        setPapersList(parsedPapersList.filter(valueFilter));
      }
    } catch (e) {
      console.log(e);
    }
    setSearching(false);
  };

  return (
      <Box className={ `${ className } ${ classes.container }` }
           style={ style }>
        {/*<Paper className={classes.paper}>*/ }
        <Box className={ classes.toolbar }>
          <Box style={ { display: 'flex' } }
               className={ classes.toolbarHeader }>
            <Typography variant="h6" id="tableTitle"
                        style={ { flex: '1 1 100%' } }
                        component="div">
              Бумага
            </Typography>
            <Tooltip title="Создать">
              <IconButton onClick={ () => {onAddClick();} }>
                <AddIcon/>
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={ classes.toolbarRow }>
            <Grid container direction='column'>
              <Grid container spacing={ 1 }>
                <Grid item xs={ 8 }>
                  <TextField
                      value={ filter.search }
                      style={ { width: '100%' } }
                      onKeyDown={ (e) => {
                        if (e.keyCode === 13) {
                          applyFilter();
                        }
                      } }
                      onChange={ e => {
                        handleFilterChange(
                            { ...filter, search: e.target.value });
                      } }
                      label="Поиск" type="search"/>
                </Grid>
                <Grid item xs={ 4 }>
                  <Autocomplete
                      options={ providers }
                      getOptionLabel={ (option) => option.title }
                      // style={{ width: 300 }}
                      onKeyDown={ (e) => {
                        if (e.keyCode === 13) {
                          applyFilter();
                        }
                      } }
                      onChange={ (e, newValue) => {
                        handleFilterChange(
                            { ...filter, provider: newValue });
                      } }
                      value={ filter.provider }
                      renderInput={ (params) =>
                          <TextField { ...params }
                                     label="Поставщик"
                                     helperText="Выберите поставщика"
                                     variant="outlined"/> }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={ 1 }>
                <Grid item xs={ 4 }>
                  <TextField
                      value={ filter.density }
                      style={ { width: '100%' } }
                      onKeyDown={ (e) => {
                        if (e.keyCode === 13) {
                          applyFilter();
                        }
                      } }
                      onChange={ e => {
                        let val = parseInt(e.target.value) || 0;
                        handleFilterChange(
                            { ...filter, density: val });
                      } }
                      label="Плотность" type="number"/>
                </Grid>
                <Grid item xs={ 2 }>
                  <TextField
                      value={ filter.width }
                      style={ { width: '100%' } }
                      onKeyDown={ (e) => {
                        if (e.keyCode === 13) {
                          applyFilter();
                        }
                      } }
                      onChange={ e => {
                        let val = parseInt(e.target.value) || 0;
                        handleFilterChange(
                            { ...filter, width: val });
                      } }
                      label="Ширина" type="number"/>
                </Grid>
                <Grid item xs={ 2 }>
                  <TextField
                      value={ filter.height }
                      style={ { width: '100%' } }
                      onKeyDown={ (e) => {
                        if (e.keyCode === 13) {
                          applyFilter();
                        }
                      } }
                      onChange={ e => {
                        let val = parseInt(e.target.value) || 0;
                        handleFilterChange(
                            { ...filter, height: val });
                      } }
                      label="Длина" type="number"/>
                </Grid>
              </Grid>
              <Grid container spacing={ 1 } justify='flex-end'>
                <Grid item>
                  <Button
                      variant="contained"
                      color="primary"
                      disabled={ searching }
                      className={ classes.button }
                      onClick={ () => applyFilter() }
                      endIcon={ <SearchIcon/> }>
                    { (searching && <CircularProgress/>)
                    || 'Искать' }
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size='small'>
            <TableHead>
              <TableRow>
                { columns.map((column) => (
                    <TableCell
                        key={ column.id }
                        align={ column.align }
                        style={ column.style }
                    >
                      { column.label }
                    </TableCell>
                )) }
              </TableRow>
            </TableHead>
            <TableBody>
              { papersList
                  .map((paper) => {
                    return (
                        <TableRow hover
                                  tabIndex={ -1 }
                                  className={ classes.tableRow }
                                  style={ {
                                    backgroundColor: getPaperTypeColor(
                                        paper.typeName),
                                  } }
                                  key={ paper.id }>
                          { columns.map((column) => {
                            const value = paper[column.key];
                            return (
                                <TableCell key={ column.id }
                                           style={ column.columnStyle }
                                           align={ column.align }>
                                  { (() => {
                                    try {
                                      return column.format
                                          ? column.format(value, paper)
                                          : value;
                                    } catch (e) {
                                      console.error(e);
                                      return value;
                                    }
                                  })() }
                                </TableCell>
                            );
                          }) }
                        </TableRow>
                    );
                  }) }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
  );
};

export default PapersTable;
