import React, { Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import UserInspect from './userInspect';
import TextField from '@material-ui/core/TextField';
import Fuse from 'fuse.js';
import ListSubheader from '@material-ui/core/ListSubheader';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: '100%',
    // justifyContent: 'space-between'
    height: '100%',
  },
  gridList: {
    height: '100%',
    padding: theme.spacing(1),
  },
  usersList: {
    overflow: 'auto',
    maxHeight: '70vh',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  userInspect: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  searchBox: {
    display: 'flex',
    padding: theme.spacing(1),
    width: '100%',
    // padding: theme.spacing(1)
  },
  redAvatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
}));

const UsersList = ({ className, users, onPasswordChange, onEditSubmit, onUserCreate, onUserDelete }) => {
  const classes = useStyles();

  const [selectedUser, setSelectedUser] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [usersList, setUsersList] = useState(users);

  const fuse = new Fuse(users, {
    keys: ['name', 'username'],
  });

  useEffect(() => {
    if (searchValue === '' || searchValue === null) {
      setUsersList(users);
    } else {
      let res = fuse.search(searchValue);
      setUsersList(res.map(v => v.item));
    }
  }, [users]);

  const handleSearchValueChange = (event) => {
    let search = event.target.value;
    if (search === '' || search === null) {
      setUsersList(users);
    } else {
      let res = fuse.search(search);
      setUsersList(res.map(v => v.item));
    }
    setSearchValue(search);
  };

  return (
      <Box className={ className }>
        <Box className={ classes.container }>
          <GridList cellHeight={ 'auto' } className={ classes.gridList }
                    cols={ 3 }>
            <GridListTile cols={ 1 }>
              <Paper className={ classes.usersList }>
                <List style={ { width: '100%',} }>
                  <ListSubheader>
                    <Paper className={ classes.searchBox }>
                      <TextField style={ { flexGrow: 1 } }
                                 value={ searchValue }
                                 onChange={ handleSearchValueChange }
                                 label="Имя Пользователя" type="search"/>
                    </Paper>
                  </ListSubheader>
                  { usersList.map((user, index) =>
                      (<Fragment key={ user.username }>
                        { index !== 0 &&
                        <Divider variant="inset" component="li"/> }
                        <ListItem onClick={ () => {setSelectedUser(user);} }
                                  selected={ selectedUser &&
                                  selectedUser.username ===
                                  user.username }
                                  alignItems="flex-start" button>
                          <ListItemAvatar>
                            <Avatar className={ classes.redAvatar }>
                              { user.name.split(' ')
                                  .slice(0, 2)
                                  .map(v => v[0])
                                  .join('') }</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                              primary={ user.name }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                      component="span"
                                      variant="body2"
                                      className={ classes.inline }
                                      color="textPrimary"
                                  >
                                    { user.username }
                                  </Typography>
                                  { ` — ${ user.roles.join(', ') }.` }
                                </React.Fragment>
                              }
                          />
                        </ListItem>
                      </Fragment>))
                  }
                </List>
              </Paper>
            </GridListTile>
            <GridListTile cols={ 2 }>
              <Paper className={ classes.userInspect }>
                { <UserInspect user={ selectedUser }
                               onEditSubmit={ async(newUserData) => {
                                 await onEditSubmit({
                                   ...selectedUser,
                                   ...newUserData,
                                 });
                               } }
                               onPasswordChange={ async(password) => {
                                 await onPasswordChange(selectedUser, password);
                               } }
                               onUserCreate={ async(newUser) => {
                                 await onUserCreate(newUser);
                               } }
                               onUserDelete={ async(user) => {
                                 await onUserDelete(user);
                               } }/> }
              </Paper>
            </GridListTile>
          </GridList>
        </Box>
      </Box>
  );
};

export default UsersList;
