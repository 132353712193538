import React, { Fragment, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { paperTypes, verbosePaperType } from '../../../api/warehouse/paper';
import {
  countryCodes,
  verboseCountryCode,
} from '../../../api/utils/countryCodes';
import { Form, Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import green from '@material-ui/core/colors/green';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CloseIcon from '@material-ui/icons/Close';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  form: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  priceHistoryPaper: {
    margin: theme.spacing(1),
    // padding: theme.spacing(1)
  }
}));

const priceChart = (records) => {
  moment.locale('ru');
  let data = [
    {
      'id': 'Цена',
      "color": "hsl(182, 70%, 50%)",
      'data': records ? records.map(record => ({
        y: record.price / 100,
        x: record.date
      })).filter(point => point.x && point.y) : [],
    },
  ];
  return (
      <ResponsiveLine
          data={ data }
          margin={ { top: 50, right: 50, bottom: 50, left: 60 } }
          xScale={ { type: 'point' } }
          yScale={ {
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          } }
          xFormat={x => moment(x*1000).format('D.M.YY HH:m:s')}
          axisTop={ null }
          axisRight={ null }
          axisBottom={ {
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Дата',
            legendOffset: 36,
            legendPosition: 'middle',
          } }
          axisLeft={ {
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Цена',
            legendOffset: -40,
            legendPosition: 'middle',
          } }
          colors={ { scheme: 'category10' } }
          pointSize={ 10 }
          pointColor={ { theme: 'background' } }
          pointBorderWidth={ 2 }
          pointBorderColor={ { from: 'serieColor' } }
          pointLabel="y"
          pointLabelYOffset={ -12 }
          useMesh={ true }
      />
  );
};

function displayPaperData(paperTableData) {
  return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/*<TableCell>Параметр</TableCell>*/ }
              {/*<TableCell align="left">Значение</TableCell>*/ }
            </TableRow>
          </TableHead>
          <TableBody>
            { paperTableData.map((row) => (
                <TableRow key={ row.name }>
                  <TableCell component="th" scope="row">
                    { row.name }
                  </TableCell>
                  <TableCell align="left">{ row.getValue() }</TableCell>
                </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
  );
}

function ErrorDialog(props) {
  const { onClose, paper, open } = props;

  function handleClose() {
    onClose(false);
  }

  return (
      <Dialog onClose={ handleClose } aria-labelledby="simple-dialog-title"
              open={ open }>
        <DialogTitle>Вы абсолютно уверены?</DialogTitle>
        <DialogContent>
          <DialogContentText component={ 'div' }>
            <Box color={ 'warning.main' }>
              <Typography variant={ 'subtitle1' }>
                Неожиданные плохие вещи произойдут, если вы не
                прочитаете
                это!
              </Typography>
            </Box>
            <Typography variant={ 'body2' }>
              Это действие не может быть отменено.
              Это навсегда удалит
              Бумагу <strong>{ paper.name }</strong>,
              цену на нее и
              ее историю цен, а также нарушит целостность
              всех другие связанные данные.
              <br/>
              Пожалуйста,
              нажмите <strong>УДАЛИТЬ</strong> для
              подтверждения.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => {onClose(false);} } color="primary">
            Отменить
          </Button>
          <Button onClick={ () => onClose(true) } color={ 'primary' }
                  variant={ 'contained' }>
            'Удалить'
          </Button>
        </DialogActions>
      </Dialog>
  );
}

const PaperView = ({
                     className, style,
                     paper, paperProviders, onEdit, onDelete, onAdd,
                     isSupplyAdmin, isSupplyMan, minimized, onMinimize, onExpand,
                   }) => {
  const formInputs = [
    {
      id: 'name',
      label: 'Название',
      isSelect: false,
      notRequired: true,
      inputType: 'text',
    },
    {
      id: 'provider',
      label: 'Поставщик',
      notRequired: true,
      isSelect: false,
      options: paperProviders,
      isAutoComplete: true,
      inputType: 'text',
    },
    {
      id: 'country',
      label: 'Страна',
      isSelect: true,
      inputType: 'text',
      items: countryCodes,
    },
    {
      id: 'typeName',
      label: 'Тип',
      isSelect: true,
      inputType: 'text',
      items: paperTypes,
    },
    {
      id: 'density',
      label: 'Плотность',
      isSelect: false,
      inputType: 'number',
      step: '0.01',
    },
    {
      id: 'group1',
      isGroup: true,
      group: [
        {
          id: 'height',
          label: 'Длина',
          isSelect: false,
          inputType: 'number',
          step: 0.01,
        },
        {
          id: 'width',
          label: 'Ширина',
          isSelect: false,
          inputType: 'number',
          step: 0.01,
        },
        {
          id: 'price',
          label: 'Цена',
          isSelect: false,
          inputType: 'number',
          step: 0.01,
          format: (value) => value / 100,
          unFormat: (value) => Math.round(value * 100),
        },
      ],
    },
  ];
  const classes = useStyles();

  const [tabIndex, setTabIndex] = React.useState(3);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

  const paperTableData = () => {
    return [
      { name: 'id', getValue: () => paper.id },
      { name: 'Название', getValue: () => paper.name },
      { name: 'Поставщик', getValue: () => paper.provider },
      { name: 'Страна', getValue: () => verboseCountryCode(paper.country) },
      { name: 'Тип', getValue: () => verbosePaperType(paper.typeName) },
      { name: 'Плотность', getValue: () => paper.density },
      { name: 'Ширина', getValue: () => paper.width },
      { name: 'Длина', getValue: () => paper.height },
      {
        getValue: () => {
          if (!paper.priceRecords || paper.priceRecords.length === 1) {
            return <Typography variant={ 'body1' }>
              { (paper.price / 100).toFixed(2) } ₸
            </Typography>;
          }
          let priceDelta = paper.price -
              paper.priceRecords[paper.priceRecords.length - 2].price;
          let isIncreased = priceDelta > 0;
          return (
              <Fragment>
                <Typography variant={ 'body1' }
                            style={ {
                              color: isIncreased
                                  ? red[500]
                                  : green[500],
                            } }>
                  <Typography variant='caption'>{ Math.abs(priceDelta / 100)
                      .toFixed(2) }</Typography>
                  { isIncreased ? <ArrowDropUpIcon
                          fontSize={ 'small' }/> :
                      <ArrowDropDownIcon fontSize={ 'small' }/> }
                  { (paper.price / 100).toFixed(2) } ₸
                </Typography>
              </Fragment>
          );
        }, name: 'Цена',
      },
    ];
  };

  const gatherInputData = (values) => formInputs.reduce((res, input) => {
    if (input.isGroup) {
      return input.group.reduce((res, input) => ({
        ...res,
        [input.id]: input.unFormat ? input.unFormat(
            values[input.id]) : values[input.id],
      }), res);
    } else {
      return {
        ...res,
        [input.id]: input.unFormat ? input.unFormat(
            values[input.id]) : values[input.id],
      };
    }
  }, {});
  const validateInputData = values => {
    const errors = {};
    formInputs.forEach(input => {
      if (input.isGroup) {
        input.group.forEach(input => {
          if (!values[input.id] && !input.notRequired) {
            errors[input.id] = 'Обязательно';
          }
        });
      } else {
        if (!values[input.id] && !input.notRequired) {
          errors[input.id] = 'Обязательно';
        }
      }
    });
    return errors;
  };

  function handleTabIndexChange(event, newValue) {
    setTabIndex(newValue);
  }

  const onErrorDialogClose = async(readyToDelete) => {
    setErrorDialogOpen(false);
    if (readyToDelete) {
      await onDelete(paper);
    }
  };

  useEffect(() => {
    if (paper && tabIndex === 3) {
      setTabIndex(0);
    } else if (!paper) {
      setTabIndex(3);
    }
  }, [paper]);

  const paperInputFields = ({
                              formInput,
                              isSubmitting,
                              errors,
                              touched,
                              handleBlur,
                              handleChange,
                              values,
                              setFieldValue,
                            }) => {
    return (
        <Fragment key={ formInput.id }>
          { (formInput.isSelect &&
              <FormControl className={ classes.input }
                           error={ errors[formInput.id] &&
                           touched[formInput.id] }
                           variant="outlined">
                <InputLabel>{ formInput.label }</InputLabel>
                <Select
                    label={ formInput.label }
                    value={ values[formInput.id] }
                    name={ formInput.id }
                    onChange={ handleChange }>
                  <MenuItem value="">
                    <em>Нету</em>
                  </MenuItem>
                  { formInput.items.map(item => (
                      <MenuItem key={ item[0] } value={ item[0] }>
                        { item[1] }
                      </MenuItem>
                  )) }
                </Select>
                <FormHelperText
                    error={ errors[formInput.id] &&
                    touched[formInput.id] }>
                  { errors[formInput.id] }
                </FormHelperText>
              </FormControl>
          ) || (formInput.isGroup && (
              <Box style={ {
                display: 'flex',
                justifyContent: 'space-between',
              } }>
                { formInput.group.map(formInput => paperInputFields({
                  formInput,
                  isSubmitting,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  values,
                  setFieldValue,
                })) }
              </Box>
          )) || (formInput.isAutoComplete && (
              <Autocomplete
                  freeSolo
                  className={ classes.input }
                  options={formInput.options}
                  getOptionLabel={(option) => option}
                  // style={{ width: 300 }}
                  onInputChange={ (_, value) => setFieldValue(formInput.id, value) }
                  onChange={ (_, value) => setFieldValue(formInput.id, value) }
                  onBlur={ handleBlur }
                  value={ values[formInput.id] }
                  renderInput={(params) =>
                      <TextField {...params}
                                 error={ errors[formInput.id] && true }
                                 label="Поставщик"
                                 helperText={ errors[formInput.id] }
                                 variant="outlined" />}
              />
          )) ||
          <TextField className={ classes.input }
                     error={ errors[formInput.id] &&
                     touched[formInput.id] }
                     helperText={ errors[formInput.id] }
                     variant="outlined"
                     label={ formInput.label }
                     type={ formInput.inputType }
                     inputProps={ { step: formInput.step } }
                     name={ formInput.id }
                     onChange={ handleChange }
                     onBlur={ handleBlur }
                     value={ values[formInput.id] }/>
          }
        </Fragment>
    );
  };

  const paperInspect = () => (
      <Box>
        <Paper square>
          <Box style={ { display: 'flex' } }>
            <IconButton onClick={ () => onMinimize() }>
              <CloseIcon/>
            </IconButton>
            <Tabs
                value={ tabIndex }
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={ handleTabIndexChange }>
              <Tab label="Анализ" disabled={ !paper }/>
              <Tab label="Изменить"
                   disabled={ !paper || (!isSupplyMan && !isSupplyAdmin) }/>
              <Tab label="Другое" disabled={ !paper || !isSupplyAdmin }/>
              <Tab label="Создать Бумагу"
                   disabled={ (!isSupplyMan && !isSupplyAdmin) }/>
            </Tabs>
          </Box>
        </Paper>
        { tabIndex === 0 && paper &&
        <Fragment>
          { displayPaperData(paperTableData()) }
          <Paper className={classes.priceHistoryPaper} elevation={3}>
            <List style={ { height: 200, overflow: 'auto' } } dense>
              { paper.priceRecords &&
              paper.priceRecords.map((record, index) => {
                let isIncreased = index === 0
                    ? false
                    : paper.priceRecords[index - 1].price - record.price < 0;
                let priceDelta = index === 0
                    ? 0
                    : Math.abs(
                        paper.priceRecords[index - 1].price - record.price);
                let percent = index === 0
                    ? 0
                    : priceDelta /
                    (paper.priceRecords[paper.priceRecords.length - 2].price /
                        100);
                return (
                    <ListItem key={ record.date }>
                      <ListItemText
                          secondary={ moment(record.date*1000).format('D.M.YY HH:m:s') }>
                        { (index === 0 &&
                            <Fragment>
                              <Typography variant={ 'body1' }>
                                { (record.price / 100).toFixed(2) } ₸
                              </Typography>
                            </Fragment>) || (
                            <Fragment>
                              <Typography variant={ 'body1' }
                                          style={ {
                                            color: isIncreased
                                                ? red[500]
                                                : green[500],
                                          } }>
                                <Typography variant='caption'>
                                  { Math.abs(percent).toFixed(2) }%
                                </Typography>
                                { isIncreased ? <ArrowDropUpIcon
                                        fontSize={ 'small' }/> :
                                    <ArrowDropDownIcon fontSize={ 'small' }/> }
                                { (record.price / 100).toFixed(2) } ₸
                              </Typography>
                            </Fragment>)
                        }
                      </ListItemText>
                    </ListItem>
                );
              }).reverse() }
            </List>
          </Paper>
          <Box style={ { height: 300 } }>
            { priceChart(paper.priceRecords) }
          </Box>
        </Fragment> }
        { tabIndex === 1 && paper &&
        <Box>
          <Formik
              initialValues={ formInputs.reduce((init, input) => {
                if (!input.isGroup) {
                  return {
                    ...init,
                    [input.id]: (input.format
                        ? input.format(paper[input.id])
                        : paper[input.id]) || '',
                  };
                } else {
                  return input.group.reduce((init, input) => ({
                    ...init,
                    [input.id]: (input.format
                        ? input.format(paper[input.id])
                        : paper[input.id]) || '',
                  }), init);
                }
              }, {}) }
              validate={ validateInputData }
              onSubmit={ (values, { setSubmitting }) => {
                return (async() => {
                  await onEdit(paper, gatherInputData(values));
                  setSubmitting(false);

                })();
              } }>
            { ({
                 isSubmitting,
                 errors,
                 touched,
                 handleBlur,
                 handleChange,
                 values,
                 setFieldValue,
               }) => (
                <Form className={ classes.form }>
                  { formInputs.map(formInput => paperInputFields({
                    formInput,
                    isSubmitting,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    values,
                    setFieldValue,
                  })) }
                  <Box className={ classes.submitButton }>
                    <Button type='submit' disabled={ isSubmitting || (
                        formInputs.reduce(
                            (acc, input) => acc || (errors[input.id] &&
                                touched[input.id]), false)
                    ) }
                            color={ 'primary' } variant={ 'contained' }>
                      { isSubmitting ?
                          <CircularProgress color="secondary"/>
                          :
                          'Сохранить'
                      }
                    </Button>
                  </Box>
                </Form>
            ) }
          </Formik>
        </Box> }
        { tabIndex === 2 && paper &&
        <Box className={ classes.paper }>
          <Typography variant='h6'>
            Опасная Зона
          </Typography>
          <Box className={ classes.paper }
               bgcolor={ 'background.paper' }
               borderColor={ 'error.main' }
               borderRadius=" borderRadius"
               border={ 1 }>
            <Box style={ { display: 'flex', flexDirection: 'column' } }>
              <Box style={ { display: 'flex' } } color="error.main">
                <Box style={ { flexGrow: 1 } } color="text.primary">
                  <Typography variant='body1'>
                    Удалить эту бумагу
                  </Typography>
                  <Typography variant='body2'>
                    После того, как вы удалите бумагу, пути назад уже не
                    будет. Пожалуйста, будьте уверены.
                  </Typography>
                </Box>
                <Box style={ { display: 'flex', alignItems: 'center' } }>
                  <Button
                      variant="outlined"
                      color="inherit"
                      className={ classes.button }
                      startIcon={ <DeleteIcon/> }
                      onClick={ () => {setErrorDialogOpen(true);} }>
                    Удалить
                  </Button>
                </Box>
                <ErrorDialog open={ errorDialogOpen }
                             onClose={ onErrorDialogClose } paper={ paper }/>
              </Box>
            </Box>
          </Box>
        </Box> }
        { tabIndex === 3 &&
        <Box>
          <Formik
              initialValues={ formInputs.reduce((init, input) => {
                if (!input.isGroup) {
                  return {
                    ...init,
                    [input.id]: '',
                  };
                } else {
                  return input.group.reduce((init, input) => ({
                    ...init,
                    [input.id]: '',
                  }), init);
                }
              }, {}) }
              validate={ validateInputData }
              onSubmit={ (values, { setSubmitting }) => {
                return (async() => {
                  await onAdd(gatherInputData(values));
                  setSubmitting(false);
                })();
              } }>
            { ({
                 isSubmitting,
                 errors,
                 touched,
                 handleBlur,
                 handleChange,
                 values,
                 setFieldValue,
               }) => (
                <Form className={ classes.form }>
                  <Typography variant={ 'h6' }>
                    Новая Бумага
                  </Typography>
                  { formInputs.map(formInput => paperInputFields({
                    formInput,
                    isSubmitting,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    values,
                    setFieldValue,
                  })) }
                  <Box className={ classes.submitButton }>
                    <Button type='submit' disabled={ isSubmitting || (
                        formInputs.reduce(
                            (acc, input) => acc || (errors[input.id] &&
                                touched[input.id]), false)
                    ) }
                            color={ 'primary' } variant={ 'contained' }>
                      { isSubmitting ?
                          <CircularProgress color="secondary"/>
                          :
                          'Сохранить'
                      }
                    </Button>
                  </Box>
                </Form>
            ) }
          </Formik>
        </Box> }
      </Box>
  );

  return (
      <Box className={ className } style={ style }>
        { !minimized && paperInspect() }
        { minimized &&
        <Box style={ { height: '100%' } }>
          <Paper square
                 style={ {
                   display: 'flex',
                   alignItems: 'center',
                   height: '100%',
                 } }>
            <Button style={ { height: '100%', width: '100%' } }
                    onClick={ () => onExpand() }>
              <KeyboardArrowLeftIcon/>
            </Button>
          </Paper>
        </Box> }
      </Box>
  );
};

export default PaperView;
