import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { red, deepOrange } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperContainerItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const AccountInformation = ({ className, user, onLogout }) => {
  const classes = useStyles();

  const avatarName = user.name.split(' ').slice(0, 2).map(v => v[0]).join('');

  return (
      <Box className={ `${ className } ${ classes.container }` }>
        <Paper className={ classes.paper }>
          <Box className={ classes.paperContainer }>
            <Avatar className={ classes.red }>{ avatarName }</Avatar>
            <Typography>{ user.name }</Typography>
            <Typography variant='caption'>{ user.username }</Typography>
          </Box>
          <Divider/>
          <Box className={`${classes.paperContainerItem} ${classes.paperContainer}`}>
            <Button variant="outlined" size='small' onClick={() => onLogout()}>Выйти</Button>
          </Box>
        </Paper>
      </Box>
  );
};

export default AccountInformation;
