const initialState = {
  token: null,
  loggedIn: false,
  user: null
};

const LOGOUT = 'LOGOUT';
const LOGIN = 'LOGIN';

function auth(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        token: null,
        loggedIn: false,
        tokenData: null,
        user: null,
      };
    case LOGIN:
      return {
        ...state,
        token: action.data.token,
        loggedIn: true,
        user: action.data.user,
        tokenData: action.data.tokenData
      };
    default:
      return state;
  }
}

export default auth;
export { LOGIN, LOGOUT };

const loginAction = (token, user, tokenData) => {
  return {
    type: LOGIN,
    data: {
      token: token,
      user: user,
      tokenData: tokenData
    },
  };
};

const logoutAction = () => {
  return {
    type: LOGOUT,
    data: {},
  };
};

export { loginAction, logoutAction };

const getAuth = (state) => state.auth;
const getUser = (state) => state.auth.user;
const getToken = (state) => state.auth.token;
export { getAuth, getUser, getToken };
