import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
// import { createBrowserHistory } from 'history';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

// const browserHistory = createBrowserHistory();

const theme = createMuiTheme({
  // status: {
    // danger: orange[500],
  // },
  palette: {
    // primary: red,
  },
});

ReactDOM.render(
    // must change to <React.StrictMode> when material ui fix find node issue.
    <React.Fragment>
      <Provider store={ store }>
        <Router>
          <ThemeProvider theme={theme}>
            {/*<Route path=''>*/ }
            <App/>
            {/*</Route>*/ }
          </ThemeProvider>
        </Router>
      </Provider>
    </React.Fragment>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
