import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Login from '../components/login';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, loginAction } from '../store/reducers/auth';
import { useHistory } from 'react-router-dom';
import { saveState } from '../store';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));

const LoginPage = ({className}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);

  const onLogin = (auth) => {
    dispatch(loginAction(auth.token, auth.user, auth.tokenData))
  };

  useEffect(() => {
    if (auth.loggedIn) {
      history.push('/')
    }
  }, [auth.loggedIn, history]);

  return (
      <Box className={classes.container}>
        <Login onLogin={onLogin}/>
      </Box>
  );
};

export default LoginPage;
