const HEX = 1;
const RGB = 2;
const RGBA = 3;

// get the string representation
// type and set it on the element (HEX/RGB/RGBA)
function getType(val) {
  if (val.indexOf('#') > -1) return HEX;
  if (val.indexOf('rgb(') > -1) return RGB;
  if (val.indexOf('rgba(') > -1) return RGBA;
}

// process the value irrespective of representation type
function processValue(color, type) {
  // eslint-disable-next-line default-case
  switch (type) {
    case HEX: {
      return processHEX(color);
    }
    case RGB:{
      return processRGB(color);
    }
    case RGBA:{
      return processRGB(color);
    }

  }
}

//return a workable RGB int array [r,g,b] from rgb/rgba representation
function processRGB(val){
  let rgb = val.split('(')[1].split(')')[0].split(',');
  alert(rgb.toString());
  return [
    parseInt(rgb[0],10),
    parseInt(rgb[1],10),
    parseInt(rgb[2],10)
  ];
}

//return a workable RGB int array [r,g,b] from hex representation
function processHEX(val) {
  //does the hex contain extra char?
  let hex = (val.length >6)?val.substr(1, val.length - 1):val;
  // is it a six character hex?
  let r, g, b;
  if (hex.length > 3) {

    //scrape out the numerics
    r = hex.substr(0, 2);
    g = hex.substr(2, 2);
    b = hex.substr(4, 2);

    // if not six character hex,
    // then work as if its a three character hex
  } else {
    // just concat the pieces with themselves
    r = hex.substr(0, 1) + hex.substr(0, 1);
    g = hex.substr(1, 1) + hex.substr(1, 1);
    b = hex.substr(2, 1) + hex.substr(2, 1);
  }
  // return our clean values
  return [
    parseInt(r, 16),
    parseInt(g, 16),
    parseInt(b, 16)
  ]
}

function makeGradient(colorStart, colorEnd, steps=24) {
  //attach start value
  let val1RGB = processValue(colorStart, getType(colorStart));
  let val2RGB = processValue(colorEnd, getType(colorEnd));
  let colors = [
    // somewhere to dump gradient
  ];
  // the pre element where we spit array to user

  //the number of steps in the gradient
  let stepsInt = steps;
  //the percentage representation of the step
  let stepsPerc = 100 / (stepsInt+1);

  // diffs between two values
  let valClampRGB = [
    val2RGB[0] - val1RGB[0],
    val2RGB[1] - val1RGB[1],
    val2RGB[2] - val1RGB[2]
  ];

  // build the color array out with color steps
  for (let i = 0; i < stepsInt; i++) {
    let clampedR = (valClampRGB[0] > 0)
        ? pad((Math.round(valClampRGB[0] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
        : pad((Math.round((val1RGB[0] + (valClampRGB[0]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

    let clampedG = (valClampRGB[1] > 0)
        ? pad((Math.round(valClampRGB[1] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
        : pad((Math.round((val1RGB[1] + (valClampRGB[1]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

    let clampedB = (valClampRGB[2] > 0)
        ? pad((Math.round(valClampRGB[2] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
        : pad((Math.round((val1RGB[2] + (valClampRGB[2]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);
    colors[i] = [
      '#',
      clampedR,
      clampedG,
      clampedB
    ].join('');
  }
  return colors
}

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function hexToRGB(hex, alpha) {
  let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

const paperTypesDict = {
  'Glossy': 'Глянцевая',
  'Matte': 'Матовая',
  'Offset': 'Офсетная',
  'Label': 'Этикеточная',
  'Newsprint': 'Газетная',
  'Kraft': 'Крафт бумага',
  'EnvelopeC4': 'Конверт С4',
  'EnvelopeC65': 'Конверт С65',
  'EnvelopeC5': 'Конверт С5',
  'SingleSidedCarton': 'Картон односторонний',
  'DoubleSidedCarton': 'Картон двухсторонний',
  'KraftCarton': 'Картон КРАФТ',
  'BeerCarton': 'Картон ПИВНОЙ',
  'SelfAdhesiveGlossy': 'Самоклеящийся Глянцевая',
  'SelfAdhesiveMatte': 'Самоклеящийся Матовая',
  'SelfCopyCbWhite': 'Самокопиры СВ белый',
  'SelfCopyCbColor': 'Самокопиры CB цветной',
  'SelfCopyCfbYellow': 'Самокопиры CFB желтый',
  'SelfCopyCfBlue': 'Самокопиры CF голубой',
  'DesignerPaper': 'Дизайнерская бумага',
  'SelfCopyCb': 'Самокопиры СВ',
  'SelfCopyCfb': 'Самокопиры CFB',
  'SelfCopyCf': 'Самокопиры CF',
  'WhitePaper': 'Белый',
};

let colors = [...makeGradient('#0000FF', '#FF0000',  12), ...makeGradient('#FF0000', '#00FF00',  12)]
let types = [
    'Glossy',
    'Matte',
    'Offset',
    'Label',
    'Newsprint',
    'Kraft',
    'EnvelopeC4',
    'EnvelopeC65',
    'EnvelopeC5',
    'SingleSidedCarton',
    'DoubleSidedCarton',
    'KraftCarton',
    'BeerCarton',
    'SelfAdhesiveGlossy',
    'SelfAdhesiveMatte',
    'SelfCopyCbWhite',
    'SelfCopyCbColor',
    'SelfCopyCfbYellow',
    'SelfCopyCfBlue',
    'DesignerPaper',
    'SelfCopyCb',
    'SelfCopyCfb',
    'SelfCopyCf',
    'WhitePaper',
]

const paperTypesColorsDict = {}
types.map(((value, index) => paperTypesColorsDict[value] = hexToRGB(colors[index], 0.1)))

export const paperTypes = Object.entries(paperTypesDict);

export const verbosePaperType = (typeName) => {
  if (!(typeName in paperTypesDict))
    return typeName;
  return paperTypesDict[typeName];
};

export const getPaperTypeColor = (typeName) => {
  if (!(typeName in paperTypesColorsDict))
    return '#000000';
  return paperTypesColorsDict[typeName]
}
