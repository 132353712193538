export const checkRole = (auth, role) => {
  return auth && auth.loggedIn && auth.user && auth.user.roles && auth.user.roles.includes(role)
}

export const checkAnyRoles = (auth, ...roles) => {
  if (!(auth && auth.loggedIn && auth.user && auth.user.roles)) {
    return false;
  }
  let rolesMap = {}
  roles.map(role => rolesMap[role] = true);
  let cnt = 0
  auth.user.roles.forEach(role => {if (rolesMap[role]) { cnt += 1 }})
  return cnt !== 0
}
