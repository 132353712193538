import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  box: {
    margin: theme.spacing(2),
    textAlign: 'center'
  },
}));


const Forbidden = () => {
  const classes = useStyles();
  return (
      <Box className={classes.box}>
        <Typography variant={'h6'}>403 Forbidden</Typography>
      </Box>
  );
};

export default Forbidden;
