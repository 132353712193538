import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import PapersManager from '../components/warehouse/papers/papersManager';
import { checkRole } from '../util';
import { useSelector } from 'react-redux';
import { getAuth, getToken } from '../store/reducers/auth';
import { makeStyles } from '@material-ui/core/styles';
import {
  buildAxios,
  paperProvidersApiUrl,
  papersApiUrl,
  usersApiUrl,
} from '../api';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => {

});

const WarehousePage = ({className, style}) => {
  const classes = useStyles();

  const auth = useSelector(getAuth);
  const isSupplyAdmin = checkRole(auth, 'supplyAdmin');
  const isSupplyMan = checkRole(auth, 'supplyMan');
  const isSupplyViewer = checkRole(auth, 'supplyViewer');
  const token = useSelector(getToken);
  const api = buildAxios(token);

  const [papers, setPapers] = useState([])
  const [paperProviders, setPaperProviders] = useState([])
  const [backdropEnabled, setBackdropEnabled] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const alertSuccess = (message) => {
    setSuccessOpen(true);
    setSuccessMessage(message)
  }

  const alertErrors = (message) => {
    setErrorOpen(true);
    setErrorMessage(message)
  }

  const handleSuccessOpenClose = () => {
    setSuccessOpen(false);
  }
  const handleErrorOpenClose = () => {
    setErrorOpen(false);
  }

  const reloadPapers = async () => {
    setBackdropEnabled(true)
    try {
      let res = await api.get(papersApiUrl)
      if (res.status === 200) {
        setPapers(res.data.papers);
      } else {
        console.log(res)
        alertErrors(res.status)
      }
    } catch (e) {
      console.error(e)
      alertErrors(e.response.status)
    }
    // no need for that.
    // try {
    //   let res = await api.get(paperProvidersApiUrl)
    //   if (res.status === 200) {
    //     setPaperProviders(res.data.providers);
    //   } else {
    //     console.log(res)
    //     alertErrors(res.status)
    //   }
    // } catch (e) {
    //   console.error(e)
    //   alertErrors(e.response.status)
    // }
    setBackdropEnabled(false)
  }

  const addPaper = async (paper) => {
    setBackdropEnabled(true)
    try {
      let res = await api.post(papersApiUrl, paper)
      if (res.status === 200) {
        alertSuccess('Бумага создана')
      } else {
        console.log(res);
        alertErrors(res.status);
      }
    } catch (e) {
      console.error(e);
      alertErrors(e.response.status);
    }
    await reloadPapers()
    setBackdropEnabled(false)
  }

  const editPaper = async (paper, newPaper) => {
    setBackdropEnabled(true)
    try {
      let res = await api.put(`${papersApiUrl}/${paper.id}`, newPaper)
      if (res.status === 200) {
        alertSuccess('Бумага изменена')
      } else {
        console.log(res);
        alertErrors(res.status);
      }
    } catch (e) {
      console.error(e);
      alertErrors(e.response.status);
    }
    await reloadPapers()
    setBackdropEnabled(false)
  }

  const deletePaper = async (paper) => {
    setBackdropEnabled(true)
    try {
      let res = await api.delete(`${papersApiUrl}/${paper.id}`)
      if (res.status === 200) {
        alertSuccess('Бумага удалена')
      } else {
        console.log(res);
        alertErrors(res.status);
      }
    } catch (e) {
      console.error(e);
      alertErrors(e.response.status);
    }
    await reloadPapers()
    setBackdropEnabled(false)
  }

  useEffect(() => {
    (async () => {
      await reloadPapers()
    })()
  }, [])

  return (
      <Box className={className} style={style}>
        <Backdrop className={ classes.backdrop } open={ backdropEnabled }>
          <CircularProgress color="inherit"/>
        </Backdrop>
        <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessOpenClose}>
          <Alert onClose={handleSuccessOpenClose} severity="success">
            { successMessage }
          </Alert>
        </Snackbar>
        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorOpenClose}>
          <Alert onClose={handleErrorOpenClose} severity="error">
            { errorMessage }
          </Alert>
        </Snackbar>
        <PapersManager papers={papers}
                       onDelete={deletePaper}
                       onAdd={addPaper}
                       onEdit={editPaper}
                       isSupplyAdmin={isSupplyAdmin}
                       isSupplyViewer={isSupplyViewer}
                       paperProviders={paperProviders}
                       isSupplyMan={isSupplyMan}/>
      </Box>
  );
};

export default WarehousePage;
