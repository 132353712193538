import React, { useEffect, useState } from 'react';
import UsersList from '../components/users/usersList';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import { getToken } from '../store/reducers/auth';
import { buildAxios, usersApiUrl } from '../api';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  usersList: {
    padding: theme.spacing(1),
    height: '600px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
}));

const UsersPage = () => {
  const classes = useStyles();
  const [backdropEnabled, setBackdropEnabled] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [users, setUsers] = useState([]);
  const token = useSelector(getToken);
  const api = buildAxios(token);

  const alertSuccess = (message) => {
    setSuccessOpen(true);
    setSuccessMessage(message)
  }

  const alertErrors = (message) => {
    setErrorOpen(true);
    setErrorMessage(message)
  }

  const handleSuccessOpenClose = () => {
    setSuccessOpen(false);
  }
  const handleErrorOpenClose = () => {
    setErrorOpen(false);
  }

  const reloadUsers = async () => {
    setBackdropEnabled(true)
    try {
      let res = await api.get(usersApiUrl)
      if (res.status === 200) {
        setUsers(res.data.users);
      } else {
        console.log(res)
        alertErrors(res.status)
      }
    } catch (e) {
      console.error(e)
      alertErrors(e.response.status)
    }
    setBackdropEnabled(false)
  }

  const addUser = async(newUser) => {
    setBackdropEnabled(true)
    try {
      let res = await api.post(usersApiUrl, newUser)
      if (res.status === 200) {
        await reloadUsers()
        alertSuccess('Пользователь создан')
      } else {
        console.log(res)
        alertErrors(res.status)
      }
    } catch (e) {
      console.error(e)
      alertErrors(e.response.status)
    }
    await reloadUsers()
    setBackdropEnabled(false)
  }

  const deleteUser = async (deleteUser) => {
    setBackdropEnabled(true)
    try {
      let res = await api.delete(`${usersApiUrl}/${deleteUser.username}`)
      if (res.status === 200) {
        await reloadUsers()
        alertSuccess('Пользователь Удален')
      } else {
        console.log(res)
        alertErrors(res.status)
      }
    } catch (e) {
      console.error(e)
      alertErrors(e.response.status)
    }
    await reloadUsers()
    setBackdropEnabled(false)
  }

  const updateUserPassword = async(user, password) => {
    setBackdropEnabled(true)
    try {
      let res = await api.post(`${usersApiUrl}/${user.username}/change/password`, {
        ...user,
        password: password
      })
      if (res.status === 200) {
        await reloadUsers()
        alertSuccess('Пароль обновлен')
      } else {
        console.log(res)
        alertErrors(res.status)
      }
    } catch (e) {
      console.error(e)
      alertErrors(e.response.status)
    }
    await reloadUsers()
    setBackdropEnabled(false)
  }

  const updateUser = async(updatedUser) => {
    setBackdropEnabled(true)
    try {
      let res = await api.put(`${usersApiUrl}/${updatedUser.username}`, updatedUser)
      console.log(res.data.users)
      if (res.status === 200) {
        await reloadUsers()
        alertSuccess('Пользователь обновлен')
      } else {
        console.log(res)
        alertErrors(res.status)
      }
    } catch (e) {
      console.error(e)
      alertErrors(e.response.status)
    }
    await reloadUsers()
    setBackdropEnabled(false)
  }

  useEffect(() => {
    (async () => {
      await reloadUsers()
    })()
  }, [])

  return (
      <Box className={ classes.container }>
        <Backdrop className={ classes.backdrop } open={ backdropEnabled }>
          <CircularProgress color="inherit"/>
        </Backdrop>
        <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessOpenClose}>
          <Alert onClose={handleSuccessOpenClose} severity="success">
            { successMessage }
          </Alert>
        </Snackbar>
        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorOpenClose}>
          <Alert onClose={handleErrorOpenClose} severity="error">
            { errorMessage }
          </Alert>
        </Snackbar>
        <UsersList users={ users }
                   className={ classes.usersList }
                   onPasswordChange={ async (user, id) => {await updateUserPassword(user, id)} }
                   onEditSubmit={ async (updatedUser) => {await updateUser(updatedUser)} }
                   onUserCreate={ async (newUser) => {await addUser(newUser)} }
                   onUserDelete={ async (user) => {await deleteUser(user)} }/>
      </Box>
  );
};

export default UsersPage;
