import React, { useEffect, useState } from 'react';
import { hostApi, version } from '../env';
import axios from 'axios';

const HomePage = ({className}) => {
  const [sversion, setSversion] = useState("")
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(hostApi + "/version")
        if (res.status === 200) {
          setSversion(res.data)
        }
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
      <div className={className}>
        Version {version} <br/>
        Server version {sversion}
      </div>
  );
};

export default HomePage;
