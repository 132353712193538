import React, { Fragment, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Form, Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  tabContainer: {
    padding: theme.spacing(1)
  },
}));

function displayUserData(userTableData) {
  return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/*<TableCell>Параметр</TableCell>*/ }
              {/*<TableCell align="left">Значение</TableCell>*/ }
            </TableRow>
          </TableHead>
          <TableBody>
            { userTableData.map((row) => (
                <TableRow key={ row.name }>
                  <TableCell component="th" scope="row">
                    { row.name }
                  </TableCell>
                  <TableCell align="left">{ row.value }</TableCell>
                </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
  );
}

function DeleteDialog(props) {
  const { onClose, user, open } = props;

  function handleClose() {
    onClose(false);
  }

  return (
      <Dialog onClose={ handleClose } aria-labelledby="simple-dialog-title"
              open={ open }>
        <DialogTitle>Вы абсолютно уверены?</DialogTitle>
        <Formik
            initialValues={ { username: '' } }
            validate={ values => {
              const errors = {};
              if (!values.username) {
                errors.username = 'Обязательно';
              }
              if (values.username !== user.username) {
                errors.username = 'Имя пользователя не совпадает';
              }
              return errors;
            } }
            onSubmit={ (values, { setSubmitting }) => {
              return (async() => {
                await onClose(values.username);
                setSubmitting(false);
              })();
            } }
        >
          { ({
               isSubmitting,
               errors,
               touched,
               handleBlur,
               handleChange,
               values,
             }) => (
              <Fragment>
                <Form>
                  <DialogContent>
                    <Box color={ 'warning.main' }>
                      <Typography variant={ 'subtitle1' }>
                        Неожиданные плохие вещи произойдут, если вы не
                        прочитаете
                        это!
                      </Typography>
                    </Box>
                    <Typography variant={ 'body2' }>
                      Это действие не может быть отменено.
                      Это навсегда удалит
                      пользователя <strong>{ user.username }</strong>,
                      проблемы и
                      комментарии, а также удалит все другие связанные данные.
                      <br/>
                      Пожалуйста,
                      введите <strong>{ user.username }</strong> для
                      подтверждения.
                    </Typography>
                    <TextField autoFocus
                               margin="dense"
                               fullWidth
                               variant="outlined"
                               name='username'
                               error={ errors.username && touched.username }
                               helperText={ errors.username }
                               label={ user.username }
                               onChange={ handleChange }
                               onBlur={ handleBlur }
                               value={ values.username }/>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={ () => {onClose(false);} } color="primary">
                      Отменить
                    </Button>
                    <Button type='submit' disabled={ isSubmitting }
                            color={ 'primary' } variant={ 'contained' }>
                      { isSubmitting ?
                          <CircularProgress color="secondary"/>
                          :
                          'Удалить'
                      }
                    </Button>
                  </DialogActions>
                </Form>
              </Fragment>
          ) }
        </Formik>
      </Dialog>
  );
}

const UserInspect = ({ className, user, onEditSubmit, onPasswordChange, onUserCreate, onUserDelete }) => {
  const classes = useStyles();

  const [tab, setTab] = React.useState(4);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const userTableData = () => {
    return [
      { name: 'Пользователь', value: user.username },
      { name: 'Имя', value: user.name },
      { name: 'Роли', value: user.roles.join(', ') },
    ];
  };

  useEffect(() => {
    if (tab === 4 && user) {
      setTab(0);
    }
  }, [user]);

  const onDeleteDialogClose = async(readyToDelete) => {
    setDeleteDialogOpen(false);
    if (readyToDelete) {
      await onUserDelete(user);
    }
  };

  return (
      <Box className={ className }>
        <Paper square position="static">
          <Tabs value={ tab } onChange={ handleTabChange }
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary">
            <Tab label="Просмотр" disabled={ !user }/>
            <Tab label="Изменить" disabled={ !user }/>
            <Tab label="Сменить пароль" disabled={ !user }/>
            <Tab label="Другое" disabled={ !user }/>
            <Tab label="Добавить пользователя"/>
          </Tabs>
        </Paper>
        {/* Display User */ }
        <Box className={classes.tabContainer}>
          { tab === 0 && user &&
          <Box className={ classes.container }>
            { displayUserData(userTableData()) }
          </Box> }
          { tab === 1 && user &&
          <Formik
              enableReinitialize
              initialValues={ {
                name: user.name,
                roles: user.roles.join(' '),
              } }
              validate={ values => {
                const errors = {};
                if (!values.name) {
                  errors.password = 'Обязателен';
                }
                // if (!values.roles) {
                //   errors.password = 'Обязателен';
                // }
                return errors;
              } }
              onSubmit={ (values, { setSubmitting }) => {
                return (async() => {
                  let roles = values.roles.split(' ')
                      .map(roles => roles.trim())
                      .reduce((res, v) => v ? res.concat(v) : res, []);
                  await onEditSubmit({
                    name: values.name,
                    roles: roles,
                  });
                  setSubmitting(false);
                })();
              } }
          >
            { ({
                 isSubmitting,
                 errors,
                 touched,
                 handleBlur,
                 handleChange,
                 values,
               }) => (
                <Form className={ classes.form }>
                  <TextField className={ classes.input }
                             error={ errors.name && touched.name }
                             helperText={ errors.name }
                             variant="outlined"
                             label="Имя"
                             name='name'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.name }/>
                  <TextField className={ classes.input }
                             error={ errors.roles && touched.roles }
                             helperText={ errors.roles }
                             variant="outlined"
                             label="Роли"
                             name='roles'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.roles }/>
                  <Box className={ classes.submitButton }>
                    <Button type='submit' disabled={ isSubmitting || (
                        (errors.name && touched.name) ||
                        (errors.roles && touched.roles)
                    ) }
                            color={ 'primary' } variant={ 'contained' }>
                      { isSubmitting ?
                          <CircularProgress color="secondary"/>
                          :
                          'Изменить'
                      }
                    </Button>
                  </Box>
                </Form>
            ) }
          </Formik> }
          { tab === 2 && user &&
          <Formik
              initialValues={ { password: '', password2: '' } }
              validate={ values => {
                const errors = {};
                if (!values.password) {
                  errors.password = 'Обязателен';
                }
                if (values.password !== values.password2) {
                  errors.password2 = 'Пароли не совпадают';
                }
                return errors;
              } }
              onSubmit={ (values, { setSubmitting }) => {
                return (async() => {
                  await onPasswordChange(values.password);
                  setSubmitting(false);
                })();
              } }
          >
            { ({
                 isSubmitting,
                 errors,
                 touched,
                 handleBlur,
                 handleChange,
                 values,
               }) => (
                <Form className={ classes.form }>
                  <TextField className={ classes.input }
                             error={ errors.password && touched.password }
                             helperText={ errors.password }
                             variant="outlined"
                             label="Пароль"
                             type='password'
                             name='password'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.password }/>
                  <TextField className={ classes.input }
                             error={ errors.password2 && touched.password2 }
                             helperText={ errors.password2 }
                             variant="outlined"
                             label="Повторите Пароль"
                             name='password2'
                             type='password'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.password2 }/>
                  <Box className={ classes.submitButton }>
                    <Button type='submit' disabled={ isSubmitting || (
                        (errors.password && touched.password) ||
                        (errors.password2 && touched.password2)
                    ) }
                            color={ 'primary' } variant={ 'contained' }>
                      { isSubmitting ?
                          <CircularProgress color="secondary"/>
                          :
                          'Сменить Пароль'
                      }
                    </Button>
                  </Box>
                </Form>
            ) }
          </Formik> }
          { tab === 3 && <Box className={ classes.paper }>
            <Typography variant='h6'>
              Опасная Зона
            </Typography>
            <Box className={ classes.paper }
                 bgcolor={ 'background.paper' }
                 borderColor={ 'error.main' }
                 borderRadius=" borderRadius"
                 border={ 1 }>
              <Box style={ { display: 'flex', flexDirection: 'column' } }>
                <Box style={ { display: 'flex' } } color="error.main">
                  <Box style={ { flexGrow: 1 } } color="text.primary">
                    <Typography variant='body1'>
                      Удалить этого пользователя
                    </Typography>
                    <Typography variant='body2'>
                      После того, как вы удалите пользователя, пути назад уже не
                      будет. Пожалуйста, будьте уверены.
                    </Typography>
                  </Box>
                  <Box style={ { display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        color="inherit"
                        className={ classes.button }
                        startIcon={ <DeleteIcon/> }
                        onClick={ () => {setDeleteDialogOpen(true);} }>
                      Удалить
                    </Button>
                  </Box>
                  <DeleteDialog open={ deleteDialogOpen }
                               onClose={ onDeleteDialogClose } user={ user }/>
                </Box>
              </Box>
            </Box>
          </Box> }
          { tab === 4 &&
          <Formik
              initialValues={ {
                name: '',
                username: '',
                roles: '',
                password: '',
                password2: '',
              } }
              validate={ values => {
                const errors = {};
                if (!values.name) {
                  errors.password = 'Обязателен';
                }
                if (!values.username) {
                  errors.username = 'Обязателен';
                }
                if (!values.password) {
                  errors.password = 'Обязателен';
                }
                if (values.password !== values.password2) {
                  errors.password2 = 'Пароли не совпадают';
                }
                // if (!values.roles) {
                //   errors.password = 'Обязателен';
                // }
                return errors;
              } }
              onSubmit={ (values, { setSubmitting }) => {
                return (async() => {
                  let roles = values.roles.split(' ')
                      .map(roles => roles.trim())
                      .reduce((res, v) => v ? res.concat(v) : res, []);
                  await onUserCreate({
                    username: values.username,
                    name: values.name,
                    roles: roles,
                    password: values.password,
                  });
                  setSubmitting(false);
                })();
              } }
          >
            { ({
                 isSubmitting,
                 errors,
                 touched,
                 handleBlur,
                 handleChange,
                 values,
               }) => (
                <Form className={ classes.form }>
                  <Typography variant={ 'h6' }>
                    Новый пользователь
                  </Typography>
                  <TextField className={ classes.input }
                             error={ errors.name && touched.name }
                             helperText={ errors.name }
                             variant="outlined"
                             label="Имя"
                             name='name'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.name }/>
                  <TextField className={ classes.input }
                             error={ errors.username && touched.username }
                             helperText={ errors.username }
                             variant="outlined"
                             label="Имя пользователя"
                             name='username'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.username }/>
                  <TextField className={ classes.input }
                             error={ errors.roles && touched.roles }
                             helperText={ errors.roles }
                             variant="outlined"
                             label="Роли"
                             name='roles'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.roles }/>
                  <TextField className={ classes.input }
                             error={ errors.password && touched.password }
                             helperText={ errors.password }
                             variant="outlined"
                             label="Пароль"
                             type='password'
                             name='password'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.password }/>
                  <TextField className={ classes.input }
                             error={ errors.password2 && touched.password2 }
                             helperText={ errors.password2 }
                             variant="outlined"
                             label="Повторите Пароль"
                             name='password2'
                             type='password'
                             onChange={ handleChange }
                             onBlur={ handleBlur }
                             value={ values.password2 }/>
                  <Box className={ classes.submitButton }>
                    <Button type='submit' disabled={ isSubmitting || (
                        (errors.name && touched.name) ||
                        (errors.username && touched.username) ||
                        (errors.roles && touched.roles) ||
                        (errors.password && touched.password) ||
                        (errors.password2 && touched.password2)
                    ) }
                            color={ 'primary' } variant={ 'contained' }>
                      { isSubmitting ?
                          <CircularProgress color="secondary"/>
                          :
                          'Создать'
                      }
                    </Button>
                  </Box>
                </Form>
            ) }
          </Formik> }
        </Box>
      </Box>
  );
};

export default UserInspect;
