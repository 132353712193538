import React, { Fragment, useEffect, useState } from 'react';
import {
  useHistory,
  Switch, useLocation,
  Route, Link, matchPath, useRouteMatch,
} from 'react-router-dom';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, getToken } from './store/selectors';
import { loginAction, logoutAction } from './store/actions';
import HomePage from './pages/homePage';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LoginPage from './pages/loginPage';
import { tokenRefreshApiUrl } from './api';
import jwt_decode from 'jwt-decode';
import Box from '@material-ui/core/Box';
import { buildAxios } from './api';
import AccountButtonPopover from './components/accountButtonPopover';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import UsersPage from './pages/usersPage';
import { checkRole } from './util';
import Forbidden from './components/forbidden';
import WarehousePage from './pages/warehousePage';
import { version } from './env';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  routesContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
}));

function App() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();
  const auth = useSelector(getAuth);

  const token = useSelector(getToken);
  const api = buildAxios(token);

  const [authBeat, setAuthBeat] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (state) => {
    setDrawerOpen(!drawerOpen);
  };

  const onLogout = () => {
    history.push('/');
    setDrawerOpen(false);
    dispatch(logoutAction());
  };

  const authHeartbeat = (state) => {
    console.log('beat');
    setAuthBeat(state);
    setTimeout(() => authHeartbeat(!state), 1000 * 15);
  };

  useEffect(() => {
    authHeartbeat(true);
  }, []);

  // refresh token whenever possible
  useEffect(() => {
    (async() => {
      if (!authBeat && auth.loggedIn) {
        // console.log(auth.tokenData.exp * 1000);
        // console.log(Date.now());
        // console.log(auth.tokenData.exp * 1000 - Date.now());
        if (true /* auth.tokenData.exp * 1000 - Date.now() <= 60 * 1000 */) {
          try {
            let res = await api.post(tokenRefreshApiUrl, {
              token: token,
            });
            if (res.status === 200) {
              console.log('Refresh successful');
              let auth = {
                token: res.data.newToken,
              };
              let decoded = jwt_decode(auth.token);
              auth.tokenData = decoded;
              auth.user = decoded.user;
              dispatch(loginAction(auth.token, auth.user, auth.tokenData));
            } else {
              console.log(res);
            }
          } catch (e) {
            if (e.response) {
              if (e.response.status === 400) {
                if (e.response.data !== 'token refreshed too soon') {
                  console.log(e);
                }
              }
              if (e.response.status === 401) {
                onLogout();
              }
            } else {
              onLogout();
            }
          }
        }
      }
    })();
  }, [authBeat, dispatch, auth.token, auth.loggedIn, auth.tokenData]);

  return (
      <Box className={ classes.container }>
        <AppBar position="static">
          <Toolbar>
            <IconButton onClick={ toggleDrawer }
                        edge="start" className={ classes.menuButton }
                        color="inherit" aria-label="menu">
              <MenuIcon/>
            </IconButton>
            <Typography variant="h6" className={ classes.title }
                        onClick={ () => history.push('/') }>
              Алейрон
            </Typography>
            { auth.loggedIn ?
                <AccountButtonPopover onLogout={ onLogout } user={ auth.user }/>
                :
                <Button color="inherit"
                        onClick={ () => history.push('/login') }>Войти</Button>
            }
          </Toolbar>
        </AppBar>
        <div className={ classes.routesContainer }>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */ }
          <Switch>
            <Route path={ `/users` }>
              { (checkRole(auth,'admin') &&
                <UsersPage/>) || <Forbidden/>
              }
            </Route>
            <Route path={ `/warehouse` }>
              { ((checkRole(auth,'supplyMan') || checkRole(auth,'supplyViewer') || checkRole(auth,'supplyAdmin')) &&
                  <WarehousePage/>) || <Forbidden/>
              }
            </Route>
            <Route path={ `/login` }>
              <LoginPage/>
            </Route>
            <Route path={ `/` }>
              <HomePage/>
            </Route>
          </Switch>
        </div>
        <Drawer anchor={ 'left' } open={ drawerOpen } onClose={ toggleDrawer }>
          <Box className={ classes.drawerList }>
            <Box style={{padding: 10}}>
              <Typography>Version {version}</Typography>
            </Box>
            <Divider/>
            <List>
              <Link to='/'
                    style={ { textDecoration: 'inherit', color: 'inherit' } }>
                <ListItem button selected={ matchPath(location.pathname,
                    { path: '/', exact: true }) !== null }>
                  <ListItemIcon><HomeIcon/></ListItemIcon>
                  <ListItemText primary={ 'Главная' }/>
                </ListItem>
              </Link>
            </List>
            <Divider/>
            { auth.loggedIn &&
            <Fragment>
              <List>
                <Link to='/warehouse'
                      style={ { textDecoration: 'inherit', color: 'inherit' } }>
                  <ListItem button
                            selected={ matchPath(location.pathname,
                                { path: '/warehouse' }) !== null }>
                    <ListItemIcon><ShowChartIcon/></ListItemIcon>
                    <ListItemText primary={ 'Склад' }/>
                  </ListItem>
                </Link>
              </List>
              <Divider/>
              <List>
                <Link to='/users'
                      style={ { textDecoration: 'inherit', color: 'inherit' } }>
                  <ListItem button
                            selected={ matchPath(location.pathname,
                                { path: '/users' }) !== null }>
                    <ListItemIcon><PeopleIcon/></ListItemIcon>
                    <ListItemText primary={ 'Пользователи' }/>
                  </ListItem>
                </Link>
              </List>
            </Fragment>
            }
          </Box>
        </Drawer>
      </Box>
  );
}

export default App;
