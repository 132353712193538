import { hostApi } from '../env';
import axios from 'axios';

export const loginApiUrl = `${ hostApi }/auth/login`;
export const usersApiUrl = `${ hostApi }/auth/users`;
export const tokenRefreshApiUrl = `${ hostApi }/auth/refresh`;
export const papersApiUrl = `${ hostApi }/warehouse/papers`;
export const paperProvidersApiUrl = `${ hostApi }/warehouse/papers/providers`;

export const buildAxios = (token) => {
  return axios.create({
    timeout: 5000,
    headers: {'Authorization': `Bearer ${token}`}
  });
};
