import React from 'react';
import Box from '@material-ui/core/Box';
import { IconButton } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import AccountInformation from './accountInformation';

const AccountButtonPopover = ({ className, onLogout, user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'account-popover' : undefined;

  return (
      <Box className={className}>
        <IconButton onClick={handleClick}
                    color="inherit">
          <AccountCircle />
        </IconButton>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
        >
          <AccountInformation onLogout={onLogout} user={user}/>
        </Popover>
      </Box>
  );
};

export default AccountButtonPopover;
